import React from "react";
import Image from "../../Assets/greenseaturtle.png";
import "./Species.css";

const GreenSeaTurtle = () => {
  return (
    <div className="cont">
      <div className="card">
        <div className="top">
          <img src={Image} alt="Green Sea Turtle" />
          <div>
            <div className="head">Green Sea Turtle</div>
            <div className="desc">
              The Green Sea Turtle (Chelonia mydas) is one of the most iconic and beloved marine turtles, known for its graceful swimming and vibrant greenish-colored fat, from
              which it gets its name. Found in tropical and subtropical waters worldwide, green sea turtles are unique among sea turtles due to their primarily herbivorous diet.
              Despite their importance in marine ecosystems, they are classified as endangered, facing significant threats from habitat loss, poaching, and climate change.
              Conservation efforts are crucial to protecting these gentle giants and ensuring their survival for future generations.
            </div>
          </div>
        </div>
        <div className="head">Characteristics and Behavior</div>
        <div className="desc">
          Green Sea Turtles are large reptiles, with adults reaching up to 5 feet in length and weighing between 300 to 500 pounds. They have a heart-shaped shell and paddle-like
          flippers that allow them to glide smoothly through the water. Unlike other sea turtles, green sea turtles are mostly herbivores as adults, feeding on seagrasses and
          algae. This diet gives their fat a greenish hue, hence their name. They are known for their migratory behavior, often traveling long distances between feeding grounds and
          nesting sites.
        </div>
        <div className="head">Habitat and Range</div>
        <div className="desc">
          Green Sea Turtles are found in tropical and subtropical waters around the globe, including the Atlantic, Pacific, and Indian Oceans. They inhabit coastal areas with
          abundant seagrass beds and coral reefs, which provide food and shelter. Green sea turtles are known to migrate across oceans to reach their natal beaches, where females
          return to lay eggs. These long migrations highlight the turtles' reliance on both oceanic and coastal ecosystems, making them vulnerable to threats across multiple
          environments.
        </div>
        <div className="head">Conservation Status and Threats</div>
        <div className="desc">
          Classified as endangered, Green Sea Turtles face numerous threats. Coastal development, pollution, and climate change impact their nesting beaches and feeding habitats.
          Poaching for their shells, eggs, and meat continues in some areas, despite legal protections. Climate change poses additional risks by altering sand temperatures at
          nesting sites, which affects the sex ratio of hatchlings. Conservation measures include habitat protection, anti-poaching regulations, and community-led initiatives to
          safeguard nesting beaches and reduce bycatch.
        </div>
        <div className="head">Why Saving the Green Sea Turtle Matters</div>
        <div className="desc">
          Green Sea Turtles play an essential role in marine ecosystems, especially in maintaining the health of seagrass beds and coral reefs. As herbivores, they help keep
          seagrass beds trimmed and healthy, which supports other marine species and enhances biodiversity. Protecting green sea turtles contributes to broader conservation
          efforts, promoting the resilience of coastal ecosystems and the biodiversity they support.
        </div>
        <div className="head">How to Help</div>
        <div className="desc">
          Supporting marine conservation organizations, such as the Sea Turtle Conservancy and the World Wildlife Fund (WWF), helps protect Green Sea Turtles and their habitats.
          Participating in beach cleanups and reducing plastic use can directly benefit these turtles, as plastic pollution is a major threat to marine life. Supporting policies
          that protect nesting sites and reduce bycatch, as well as raising awareness about the importance of sea turtles, are other impactful ways to contribute.
        </div>
      </div>
      <a href="/">Go Back</a>
    </div>
  );
};

export default GreenSeaTurtle;
