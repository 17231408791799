import React from "react";
import Image from "../../Assets/seaotter.png";
import "./Species.css";

const SeaOtter = () => {
  return (
    <div className="cont">
      <div className="card">
        <div className="top">
          <img src={Image} alt="Sea Otter" />
          <div>
            <div className="head">Sea Otter</div>
            <div className="desc">
              The Sea Otter (Enhydra lutris) is a charismatic and highly social marine mammal known for its playful behavior and thick fur, which is the densest of any animal.
              Found along the coasts of the northern and eastern North Pacific Ocean, sea otters play a crucial role in maintaining kelp forest ecosystems. Their populations were
              significantly reduced due to hunting for their fur in the past, but ongoing conservation efforts are helping to restore them. However, they still face threats from
              oil spills, pollution, and climate change, which impact their habitats and food sources.
            </div>
          </div>
        </div>
        <div className="head">Characteristics and Behavior</div>
        <div className="desc">
          Sea otters are known for their thick, water-resistant fur, which provides insulation in cold waters. They are excellent swimmers, using their webbed feet and muscular
          tails to navigate coastal waters. Sea otters have a unique feeding behavior, often using rocks as tools to crack open shellfish. They are social animals, frequently found
          resting together in groups called "rafts." These otters primarily feed on sea urchins, crabs, clams, and other invertebrates, playing a key role in controlling prey
          populations.
        </div>
        <div className="head">Habitat and Range</div>
        <div className="desc">
          Sea otters inhabit coastal waters along the North Pacific, including areas off the coasts of Alaska, California, and Russia. They rely on kelp forests and rocky
          coastlines for shelter and food. Kelp forests not only provide sea otters with food but also protection from predators. These habitats are highly productive ecosystems
          that support a diverse range of marine life. The health of kelp forests is closely tied to sea otter populations, as otters help control sea urchin numbers, preventing
          overgrazing of kelp.
        </div>
        <div className="head">Conservation Status and Threats</div>
        <div className="desc">
          Although sea otter populations have rebounded in some areas, they remain threatened and are classified as endangered in parts of their range. Major threats include oil
          spills, which are catastrophic due to their dependence on clean, insulating fur; pollution, which affects their food sources; and climate change, which impacts kelp
          forests. Sea otters are also susceptible to diseases and are affected by increased boat traffic and fishing activities in their habitats.
        </div>
        <div className="head">Why Saving the Sea Otter Matters</div>
        <div className="desc">
          Sea otters are a keystone species in kelp forest ecosystems, meaning their presence has a profound effect on the balance of the ecosystem. By feeding on sea urchins and
          other herbivores, they prevent overgrazing of kelp forests, which serve as critical habitats for many marine species. Protecting sea otters not only preserves this unique
          species but also ensures the health and resilience of coastal ecosystems that benefit marine biodiversity.
        </div>
        <div className="head">How to Help</div>
        <div className="desc">
          Supporting organizations like the Monterey Bay Aquarium’s Sea Otter Program and Oceana, which focus on marine conservation and sea otter protection, can make a positive
          impact. Reducing plastic use, supporting sustainable seafood choices, and raising awareness about the importance of clean, healthy oceans are also effective ways to help.
          Advocating for policies that protect sea otter habitats, reduce pollution, and mitigate climate change can further aid in the conservation of sea otters and their
          ecosystems.
        </div>
      </div>
      <a href="/">Go Back</a>
    </div>
  );
};

export default SeaOtter;
