import React from "react";
import Image from "../../Assets/bluewhale.png";
import "./Species.css";

const BlueWhale = () => {
  return (
    <div className="cont">
      <div className="card">
        <div className="top">
          <img src={Image} alt="Blue Whale" />
          <div>
            <div className="head">Blue Whale</div>
            <div className="desc">
              The Blue Whale (Balaenoptera musculus) is the largest animal ever known to have existed on Earth, reaching lengths of up to 100 feet and weighing as much as 200 tons.
              Found in oceans around the globe, these gentle giants are filter feeders, primarily consuming krill in massive quantities to sustain their enormous size. Despite
              their colossal presence in the marine world, blue whales are currently endangered, facing numerous threats from human activities. Conservation efforts are vital to
              protect this iconic species and ensure its survival in our oceans.
            </div>
          </div>
        </div>
        <div className="head">Characteristics and Behavior</div>
        <div className="desc">
          Blue whales are known for their immense size and long, streamlined bodies, which can be a striking blue-gray color that appears almost blue when underwater. They are
          baleen whales, using comb-like baleen plates in their mouths to filter thousands of pounds of krill and small fish. Blue whales communicate with each other over vast
          distances through low-frequency calls, some of the loudest sounds produced by any animal. Despite their size, blue whales are graceful swimmers, often traveling alone or
          in pairs.
        </div>
        <div className="head">Habitat and Range</div>
        <div className="desc">
          Blue whales are found in all the world's oceans, except for the Arctic. They migrate seasonally, moving to warmer tropical waters during winter months to breed and
          returning to colder regions in the summer to feed. These migratory patterns are critical to their life cycle, enabling them to access the vast krill populations needed
          for sustenance. Blue whales prefer deep offshore waters, yet their migratory routes often put them in proximity to human activities, increasing their exposure to threats.
        </div>
        <div className="head">Conservation Status and Threats</div>
        <div className="desc">
          Blue whales are listed as endangered due to historical whaling, which drastically reduced their populations in the 20th century. Although commercial whaling is no longer
          a significant threat, blue whales face new challenges such as ship strikes, noise pollution, and climate change. Ship collisions are particularly dangerous in busy
          shipping routes, and the increasing noise in the oceans interferes with their communication. Climate change affects their feeding grounds by disrupting krill populations,
          further threatening the species.
        </div>
        <div className="head">Why Saving the Blue Whale Matters</div>
        <div className="desc">
          Blue whales play an important role in marine ecosystems. As top predators, they help maintain the balance of the marine food web. Their presence also indicates the health
          of the ocean, as they depend on rich krill populations, which thrive in productive, biodiverse waters. Saving blue whales contributes to broader conservation goals,
          including the protection of the ocean's biodiversity and the resilience of marine ecosystems.
        </div>
        <div className="head">How to Help</div>
        <div className="desc">
          Supporting organizations focused on marine conservation, such as the International Fund for Animal Welfare (IFAW) and the Whale and Dolphin Conservation (WDC), is an
          effective way to help protect blue whales. Advocating for marine protected areas and sustainable shipping practices can reduce human impact on their habitats. Raising
          awareness about the importance of protecting blue whales and reducing carbon footprints to combat climate change are also essential steps toward ensuring their survival.
        </div>
      </div>
      <a href="/">Go Back</a>
    </div>
  );
};

export default BlueWhale;
