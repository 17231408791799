import React from "react";
import Image from "../../Assets/vaquita.png";
import "./Species.css";

const Vaquita = () => {
  return (
    <div className="cont">
      <div className="card">
        <div className="top">
          <img src={Image} alt="Vaquita" />
          <div>
            <div className="head">Vaquita</div>
            <div className="desc">
              The Vaquita (Phocoena sinus), known as the "panda of the sea" for its distinctive dark eye patches, is the world’s most endangered marine mammal. Endemic to the
              northern part of the Gulf of California, Mexico, this small and elusive porpoise faces critical threats, with fewer than 20 individuals estimated to remain in the
              wild. The primary driver of the vaquita's decline is bycatch, particularly in illegal gillnet fishing for the totoaba fish. Conservation efforts are urgently needed
              to prevent the extinction of this unique species and to protect the ecosystem it inhabits.
            </div>
          </div>
        </div>
        <div className="head">Characteristics and Behavior</div>
        <div className="desc">
          Vaquitas are the smallest of all porpoises, measuring only 4 to 5 feet in length and weighing between 90 to 120 pounds. They have a stocky build with rounded heads and
          lack the pronounced beak seen in dolphins. Vaquitas are characterized by their dark eye patches and lip markings, which contrast with their pale gray bodies. Typically
          solitary, they are known for being shy and elusive, surfacing briefly for air and often avoiding human contact, which makes them difficult to observe in the wild.
        </div>
        <div className="head">Habitat and Range</div>
        <div className="desc">
          Vaquitas are found exclusively in the shallow, murky waters of the northern Gulf of California, Mexico. They inhabit coastal waters within 15 miles of the shore, where
          they feed on small fish, crustaceans, and cephalopods. This limited range makes them particularly vulnerable to environmental changes and human activities, as their
          habitat overlaps with intensive fishing activities, including the illegal use of gillnets that pose a severe threat to their survival.
        </div>
        <div className="head">Conservation Status and Threats</div>
        <div className="desc">
          The vaquita is critically endangered, primarily due to bycatch from illegal gillnet fishing aimed at capturing the totoaba, whose swim bladder is in high demand in
          traditional medicine markets. The Mexican government has implemented a gillnet ban in the vaquita's habitat, but illegal fishing persists, making enforcement challenging.
          Conservation groups are working tirelessly to protect the vaquita, focusing on enforcing fishing restrictions, promoting safe fishing alternatives, and raising global
          awareness about the species' dire situation.
        </div>
        <div className="head">Why Saving the Vaquita Matters</div>
        <div className="desc">
          As a top predator in the Gulf of California’s ecosystem, the vaquita plays a critical role in maintaining the balance of the marine food web. Protecting the vaquita also
          has broader implications for ocean health, highlighting the need for sustainable fishing practices and ecosystem preservation. The vaquita has become a symbol of the
          urgent need for marine conservation, and its survival would be a powerful testament to effective conservation efforts on a global scale.
        </div>
        <div className="head">How to Help</div>
        <div className="desc">
          Supporting organizations like the World Wildlife Fund (WWF) and Sea Shepherd Conservation Society, which are dedicated to vaquita conservation, can make a direct impact.
          Raising awareness about the vaquita’s plight and advocating for sustainable seafood choices help reduce the demand for harmful fishing practices. Donating to conservation
          initiatives and supporting policies that protect marine species and habitats are other impactful ways to contribute to saving the vaquita from extinction.
        </div>
      </div>
      <a href="/">Go Back</a>
    </div>
  );
};

export default Vaquita;
