import React from "react";
import Image from "../../Assets/leatherbackturtle.png";
import "./Species.css";

const LeatherbackTurtle = () => {
  return (
    <div className="cont">
      <div className="card">
        <div className="top">
          <img src={Image} alt="Leatherback Turtle" />
          <div>
            <div className="head">Leatherback Turtle</div>
            <div className="desc">
              The Leatherback Turtle (Dermochelys coriacea) is the largest of all sea turtle species and is unique for its lack of a hard shell. Instead, its back is covered with a
              tough, leathery skin, giving the species its name. Leatherbacks can grow up to 7 feet long and weigh over 2,000 pounds, making them one of the ocean’s most remarkable
              reptiles. They inhabit the world’s tropical and subtropical oceans and are known for their extensive migrations, traveling thousands of miles annually. Despite their
              incredible adaptability, leatherbacks are critically endangered due to threats like habitat loss, bycatch, and plastic pollution.
            </div>
          </div>
        </div>
        <div className="head">Characteristics and Behavior</div>
        <div className="desc">
          Leatherback Turtles are distinguished by their flexible, leathery shell, which allows them to dive to depths of over 4,000 feet in search of jellyfish, their primary food
          source. They have long, paddle-like flippers that are highly adapted for open-ocean swimming. Leatherbacks are known for their impressive endurance, migrating vast
          distances between feeding and nesting grounds. They can tolerate colder waters better than other sea turtles due to unique adaptations that regulate their body
          temperature.
        </div>
        <div className="head">Habitat and Range</div>
        <div className="desc">
          Leatherback Turtles have one of the widest distributions of any reptile, ranging across the Atlantic, Pacific, and Indian Oceans. They migrate between temperate and
          tropical regions, with nesting occurring on sandy beaches in tropical climates. Leatherbacks are highly migratory and often travel thousands of miles between feeding
          areas and nesting beaches. Their reliance on both coastal and open ocean habitats exposes them to a variety of threats across these diverse environments.
        </div>
        <div className="head">Conservation Status and Threats</div>
        <div className="desc">
          Leatherback Turtles are classified as critically endangered, with populations declining due to multiple threats. Bycatch in fishing gear, such as longlines and trawls,
          poses a significant risk to leatherbacks. Habitat loss from coastal development and artificial lighting disrupts their nesting sites. Additionally, plastic pollution is
          especially dangerous for leatherbacks, as they often mistake plastic bags for jellyfish. Climate change also affects sand temperatures at nesting sites, which can alter
          hatchling sex ratios and reduce hatchling survival.
        </div>
        <div className="head">Why Saving the Leatherback Turtle Matters</div>
        <div className="desc">
          Leatherback Turtles play a critical role in marine ecosystems, especially in controlling jellyfish populations, which helps maintain the balance of marine food webs. As
          long-distance migrants, they contribute to the health of multiple oceanic regions. Protecting leatherbacks also supports biodiversity, as their conservation ensures the
          protection of nesting beaches that are shared by other species. They are an iconic species, representing the importance of global efforts to protect migratory wildlife.
        </div>
        <div className="head">How to Help</div>
        <div className="desc">
          Supporting marine conservation organizations like the Sea Turtle Conservancy and Ocean Conservancy can help leatherback turtle protection efforts. Reducing plastic use,
          participating in beach cleanups, and supporting sustainable seafood choices are practical ways to protect leatherbacks from threats like plastic pollution and bycatch.
          Advocating for marine protected areas and regulations that reduce fishing gear impact are other impactful ways to contribute to leatherback turtle conservation.
        </div>
      </div>
      <a href="/">Go Back</a>
    </div>
  );
};

export default LeatherbackTurtle;
