import React from "react";
import Image from "../../Assets/atlanticbluefintuna.png";
import "./Species.css";

const AtlanticBluefinTuna = () => {
  return (
    <div className="cont">
      <div className="card">
        <div className="top">
          <img src={Image} alt="Atlantic Bluefin Tuna" />
          <div>
            <div className="head">Atlantic Bluefin Tuna</div>
            <div className="desc">
              The Atlantic Bluefin Tuna (Thunnus thynnus) is a remarkable and powerful fish, known for its speed, strength, and striking appearance. As one of the largest tuna
              species, it can reach lengths of over 10 feet and weigh up to 1,500 pounds. Native to both the western and eastern Atlantic Ocean, the Atlantic Bluefin Tuna is highly
              prized in global markets, especially for sushi and sashimi, making it one of the most economically valuable fish in the world. Unfortunately, this high demand has led
              to severe overfishing, placing the species at risk of extinction. Conservation efforts are critical to ensure the survival of this iconic species and the health of
              ocean ecosystems.
            </div>
          </div>
        </div>
        <div className="head">Characteristics and Behavior</div>
        <div className="desc">
          Atlantic Bluefin Tunas are known for their remarkable speed and strength, reaching speeds of up to 40 miles per hour. They are capable of long migrations, traveling
          across the Atlantic to find optimal spawning and feeding grounds. This species has a distinct, torpedo-shaped body with dark blue coloring on the top and silvery white
          below, providing camouflage in the ocean depths. Bluefin Tunas are also known for their endothermic capabilities, which allow them to regulate their body temperature,
          giving them an advantage in colder waters.
        </div>
        <div className="head">Habitat and Range</div>
        <div className="desc">
          Atlantic Bluefin Tuna inhabit both the western and eastern Atlantic Ocean and the Mediterranean Sea. In the western Atlantic, they range from Newfoundland in Canada to
          the Gulf of Mexico, while in the eastern Atlantic, they are found from Norway to the Canary Islands. Their spawning grounds include the Gulf of Mexico and the
          Mediterranean Sea. These migratory fish traverse large distances annually, moving to cooler northern waters during warmer months and returning south to spawn.
        </div>
        <div className="head">Conservation Status and Threats</div>
        <div className="desc">
          Overfishing, driven by high demand in international markets, has severely impacted Atlantic Bluefin Tuna populations, leading to their classification as endangered by the
          IUCN. Despite international regulations and quotas, illegal fishing and unreported catches continue to threaten the species. Climate change and habitat degradation also
          pose risks, affecting the availability of prey and suitable spawning conditions. Conservation organizations and international agreements like CITES and ICCAT work to
          enforce fishing limits and promote sustainable practices, but challenges remain in ensuring compliance.
        </div>
        <div className="head">Why Saving the Atlantic Bluefin Tuna Matters</div>
        <div className="desc">
          The Atlantic Bluefin Tuna is a top predator in the marine ecosystem and plays a significant role in maintaining the balance of the ocean's food web. Its presence helps
          regulate populations of smaller fish and contributes to overall marine biodiversity. Protecting this species not only preserves an iconic fish but also supports the
          health of ocean ecosystems and the livelihoods of fishing communities. Sustainable management of Atlantic Bluefin Tuna fisheries can provide long-term ecological and
          economic benefits.
        </div>
        <div className="head">How to Help</div>
        <div className="desc">
          Supporting sustainable seafood choices, such as those certified by the Marine Stewardship Council (MSC), can help reduce demand for unsustainable tuna fishing practices.
          Donating to organizations like the World Wildlife Fund (WWF) and Oceana, which advocate for sustainable fisheries and protection of Atlantic Bluefin Tuna, also makes a
          positive impact. Raising awareness and supporting policies that enforce fishing quotas and protect spawning grounds are essential steps toward ensuring the survival of
          the Atlantic Bluefin Tuna.
        </div>
      </div>
      <a href="/">Go Back</a>
    </div>
  );
};

export default AtlanticBluefinTuna;
