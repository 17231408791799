import React from "react";
import Image from "../../Assets/nassaugrouper.png";
import "./Species.css";

const NassauGrouper = () => {
  return (
    <div className="cont">
      <div className="card">
        <div className="top">
          <img src={Image} alt="Nassau Grouper" />
          <div>
            <div className="head">Nassau Grouper</div>
            <div className="desc">
              The Nassau Grouper (Epinephelus striatus) is a vibrant, reef-dwelling fish found in the western Atlantic Ocean, particularly in the Caribbean Sea. Known for its
              distinctive coloration and large, rounded body, this species plays an important role in coral reef ecosystems. However, the Nassau Grouper has been classified as
              endangered due to overfishing, as it is highly valued in commercial and recreational fisheries. Conservation efforts are crucial to protect this species, whose
              populations have significantly declined, especially in areas without effective fishing regulations.
            </div>
          </div>
        </div>
        <div className="head">Characteristics and Behavior</div>
        <div className="desc">
          Nassau Groupers are medium to large-sized fish, reaching up to 4 feet in length and weighing around 55 pounds. They have a distinctive color pattern with vertical stripes
          and a large mouth, ideal for ambushing prey. Nassau Groupers are solitary but gather in large numbers for spawning, which typically occurs once a year. During this time,
          they become particularly vulnerable to overfishing. These groupers feed primarily on smaller fish, crustaceans, and octopuses, playing a crucial role as predators in
          coral reef ecosystems.
        </div>
        <div className="head">Habitat and Range</div>
        <div className="desc">
          Nassau Groupers are found in coral reef habitats throughout the Caribbean, the Bahamas, and parts of the Gulf of Mexico. They prefer shallow waters near coral formations,
          which offer plenty of hiding spots and feeding grounds. They can also be found at greater depths along reef edges and rocky outcrops. Their habitat preference makes them
          a vital part of reef ecosystems, as they help control populations of smaller reef fish and crustaceans.
        </div>
        <div className="head">Conservation Status and Threats</div>
        <div className="desc">
          Due to their predictable spawning behavior, Nassau Groupers have been heavily targeted by fisheries, leading to a sharp decline in populations. Overfishing, habitat
          degradation, and the absence of effective regulations in many areas are significant threats. The species is now listed as endangered, and conservation efforts focus on
          protecting spawning aggregations and enforcing fishing bans during the breeding season. Marine protected areas and catch limits are also being implemented to aid
          recovery.
        </div>
        <div className="head">Why Saving the Nassau Grouper Matters</div>
        <div className="desc">
          Nassau Groupers are important for maintaining healthy coral reef ecosystems. As predators, they help control populations of smaller fish and invertebrates, supporting
          reef biodiversity. Additionally, their presence is essential for the balance of marine food webs. Protecting Nassau Groupers not only benefits the species itself but also
          promotes the resilience and sustainability of coral reef habitats that support a wide range of marine life.
        </div>
        <div className="head">How to Help</div>
        <div className="desc">
          Supporting conservation organizations like The Nature Conservancy and local marine protection groups can help protect the Nassau Grouper. Advocating for sustainable
          fishing practices and supporting policies that enforce seasonal fishing bans during spawning periods are effective ways to aid in conservation. Raising awareness about
          the importance of protecting coral reef ecosystems and the species that depend on them, such as the Nassau Grouper, is also vital for long-term conservation success.
        </div>
      </div>
      <a href="/">Go Back</a>
    </div>
  );
};

export default NassauGrouper;
