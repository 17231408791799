import React from "react";
import Image from "../../Assets/horseshoecrab.png";
import "./Species.css";

const HorseshoeCrab = () => {
  return (
    <div className="cont">
      <div className="card">
        <div className="top">
          <img src={Image} alt="Horseshoe Crab" />
          <div>
            <div className="head">Horseshoe Crab</div>
            <div className="desc">
              The Horseshoe Crab (Limulus polyphemus) is a remarkable and ancient marine arthropod, often called a "living fossil" due to its existence on Earth for over 450
              million years. Recognized by its hard, dome-shaped shell and long, spiny tail, the horseshoe crab is found along the eastern coasts of North America and Asia. Despite
              its name, it is more closely related to spiders than to crabs. Horseshoe crabs play a vital role in coastal ecosystems and are also significant to biomedical
              research, yet they face numerous threats from habitat loss, overharvesting, and climate change.
            </div>
          </div>
        </div>
        <div className="head">Characteristics and Behavior</div>
        <div className="desc">
          Horseshoe crabs are easily identified by their unique body structure, which includes a hard, horseshoe-shaped shell, compound eyes, and a long, pointed tail called a
          telson, used primarily for flipping themselves over if turned upside down. They are bottom-dwellers, feeding on small clams, worms, and algae. Horseshoe crabs are known
          for their spawning behavior, where thousands gather on beaches to lay eggs. These eggs are crucial food sources for migrating shorebirds, making horseshoe crabs an
          integral part of coastal food webs.
        </div>
        <div className="head">Habitat and Range</div>
        <div className="desc">
          Horseshoe crabs inhabit shallow coastal waters along the Atlantic coast of North America and parts of Southeast Asia. They prefer sandy or muddy bottoms where they can
          easily burrow. During spawning season, horseshoe crabs migrate to sandy beaches to lay eggs. This seasonal migration is essential for both the survival of horseshoe crabs
          and the shorebirds that depend on their eggs. Their restricted range and specific habitat needs make them vulnerable to changes in coastal environments.
        </div>
        <div className="head">Conservation Status and Threats</div>
        <div className="desc">
          Horseshoe crabs are vulnerable due to habitat loss from coastal development and overharvesting for use as bait and in the biomedical industry. Their blue blood contains a
          substance called Limulus Amebocyte Lysate (LAL), which is invaluable for testing bacterial contamination in medical equipment and vaccines. Although synthetic
          alternatives exist, the demand for LAL continues to impact horseshoe crab populations. Conservation efforts focus on regulating harvesting, promoting synthetic LAL use,
          and protecting their spawning habitats.
        </div>
        <div className="head">Why Saving the Horseshoe Crab Matters</div>
        <div className="desc">
          Horseshoe crabs play a critical role in both marine and coastal ecosystems. Their eggs provide essential food for migratory shorebirds, and their unique ecological niche
          contributes to biodiversity. Additionally, their contributions to medical science through LAL testing have saved countless lives. Preserving horseshoe crab populations
          supports coastal biodiversity, shorebird survival, and continued advances in biomedical research.
        </div>
        <div className="head">How to Help</div>
        <div className="desc">
          Supporting organizations that work to protect horseshoe crab habitats, such as The Nature Conservancy and local coastal conservation groups, can help preserve this
          ancient species. Reducing demand for horseshoe crab blood by supporting the use of synthetic LAL in medical testing is another impactful step. Volunteering for beach
          cleanups, especially around spawning areas, and raising awareness about the importance of horseshoe crabs are other ways to contribute.
        </div>
      </div>
      <a href="/">Go Back</a>
    </div>
  );
};

export default HorseshoeCrab;
