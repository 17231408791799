import React from "react";
import Image from "../../Assets/marineiguana.png";
import "./Species.css";

const MarineIguana = () => {
  return (
    <div className="cont">
      <div className="card">
        <div className="top">
          <img src={Image} alt="Marine Iguana" />
          <div>
            <div className="head">Marine Iguana</div>
            <div className="desc">
              The Marine Iguana (Amblyrhynchus cristatus) is a unique species of lizard found only on the Galápagos Islands. Known for its distinctive ability to forage in the
              ocean, the marine iguana is the only sea-going lizard in the world. It has adapted to a life in and around water, feeding primarily on algae found on underwater
              rocks. Although these remarkable iguanas are resilient to their rugged habitat, they are vulnerable to threats such as climate change, pollution, and human
              encroachment, making their conservation critical to preserving the unique biodiversity of the Galápagos.
            </div>
          </div>
        </div>
        <div className="head">Characteristics and Behavior</div>
        <div className="desc">
          Marine iguanas have dark, rough skin and a flattened tail, which aids them in swimming. They are also known for their salt-excreting glands, located near their nostrils,
          which help them regulate salt intake from their ocean diet. Marine iguanas are social creatures, often found basking together on the rocky shores to regulate their body
          temperature after swimming in the cool ocean waters. They are herbivores, feeding mainly on algae, which they scrape off rocks with their blunt snouts.
        </div>
        <div className="head">Habitat and Range</div>
        <div className="desc">
          Marine iguanas are endemic to the Galápagos Islands, with various subspecies found on different islands. They inhabit rocky shorelines where they can easily access algae
          in the shallow waters. These iguanas rely on both land and sea to survive, basking on land to warm up and foraging underwater for food. The harsh conditions of the
          Galápagos, including strong waves and sharp rocks, have led to unique adaptations that allow them to thrive in this environment.
        </div>
        <div className="head">Conservation Status and Threats</div>
        <div className="desc">
          Although the Marine Iguana is listed as vulnerable, it faces numerous threats. Climate change impacts algae growth, which is their primary food source. Pollution, such as
          plastic waste, affects their coastal habitat, and introduced species, like rats and feral cats, pose risks to their eggs and young. Additionally, human encroachment
          disrupts their natural habitat. Conservation efforts focus on habitat protection, invasive species control, and reducing human impact on the Galápagos ecosystem.
        </div>
        <div className="head">Why Saving the Marine Iguana Matters</div>
        <div className="desc">
          Marine iguanas are a key species in the Galápagos ecosystem, contributing to the unique biodiversity of the islands. As herbivores, they play a role in controlling algae
          growth, which supports marine balance. Protecting marine iguanas helps preserve the integrity of the Galápagos environment, which is home to many other endemic species.
          They are an iconic example of evolutionary adaptation, illustrating the interconnectedness of life on the islands.
        </div>
        <div className="head">How to Help</div>
        <div className="desc">
          Supporting organizations focused on protecting the Galápagos Islands, such as the Galápagos Conservancy, is an effective way to help marine iguana conservation.
          Advocating for sustainable tourism practices, reducing plastic use, and spreading awareness about the importance of protecting this unique ecosystem can also make a
          difference. Volunteering for or donating to invasive species control programs also contributes to preserving the Galápagos and its iconic wildlife.
        </div>
      </div>
      <a href="/">Go Back</a>
    </div>
  );
};

export default MarineIguana;
