import React from "react";
import Image from "../../Assets/giantclam.png";
import "./Species.css";

const GiantClam = () => {
  return (
    <div className="cont">
      <div className="card">
        <div className="top">
          <img src={Image} alt="Giant Clam" />
          <div>
            <div className="head">Giant Clam</div>
            <div className="desc">
              The Giant Clam (Tridacna gigas) is one of the largest and most striking mollusks in the ocean, reaching lengths of up to 4 feet and weighing over 500 pounds. Found
              in the warm waters of the Indo-Pacific region, these clams are known for their vibrant colors, which come from symbiotic algae living in their tissues. Despite their
              massive size and unique appearance, giant clams are vulnerable to threats from overharvesting, habitat loss, and climate change. Conservation of this iconic species
              is crucial for maintaining the health of coral reef ecosystems where they thrive.
            </div>
          </div>
        </div>
        <div className="head">Characteristics and Behavior</div>
        <div className="desc">
          Giant clams are filter feeders, obtaining nutrients by filtering plankton from the water. They also have a symbiotic relationship with algae called zooxanthellae, which
          live in their tissues and provide the clams with additional energy through photosynthesis. This relationship gives giant clams their brilliant colors, ranging from
          electric blues and greens to purples. Unlike other mollusks, giant clams are stationary and remain anchored to one spot on the reef throughout their lives.
        </div>
        <div className="head">Habitat and Range</div>
        <div className="desc">
          Giant clams are found primarily in the shallow, warm waters of the Indo-Pacific region, including areas around the Great Barrier Reef, Southeast Asia, and the Indian
          Ocean. They prefer coral reef habitats, where sunlight can reach them to support the algae in their tissues. Living in these shallow waters makes them vulnerable to human
          activities, including coastal development, fishing, and pollution.
        </div>
        <div className="head">Conservation Status and Threats</div>
        <div className="desc">
          Giant clams are classified as vulnerable due to overharvesting for their meat and shells, which are highly valued in some cultures. Their slow growth and long lifespan
          make them particularly susceptible to exploitation. Climate change also poses a significant threat, as rising sea temperatures and ocean acidification impact coral reef
          ecosystems and the algae that giant clams rely on for food. Conservation efforts focus on protecting their habitats, regulating harvesting, and supporting aquaculture to
          replenish wild populations.
        </div>
        <div className="head">Why Saving the Giant Clam Matters</div>
        <div className="desc">
          Giant clams play a vital role in coral reef ecosystems. As filter feeders, they help maintain water quality by filtering out plankton and nutrients. They also provide
          habitat and shelter for various reef organisms, contributing to the biodiversity of coral reefs. Protecting giant clams supports the health of coral reefs, which are
          essential for marine biodiversity, fisheries, and coastal protection.
        </div>
        <div className="head">How to Help</div>
        <div className="desc">
          Supporting sustainable seafood practices and avoiding products made from giant clam shells can help reduce demand for this endangered species. Donating to conservation
          organizations that work to protect coral reefs, such as the Coral Triangle Initiative and the World Wildlife Fund (WWF), is another impactful way to contribute. Raising
          awareness about the importance of giant clams and coral reefs helps foster a collective effort to preserve these critical marine ecosystems.
        </div>
      </div>
      <a href="/">Go Back</a>
    </div>
  );
};

export default GiantClam;
