import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../Screens/Home/Home";
import Vaquita from "../Screens/Species/Vaquita";
import AtlanticBluefinTuna from "../Screens/Species/AtlanticBluefinTuna";
import BlueWhale from "../Screens/Species/BlueWhale";
import GiantClam from "../Screens/Species/GiantClam";
import GreatHammerheadShark from "../Screens/Species/GreatHammerheadShark";
import GreenSeaTurtle from "../Screens/Species/GreenSeaTurtle";
import HorseshoeCarb from "../Screens/Species/HorseshoeCarb";
import LeatherbackTurtle from "../Screens/Species/LeatherbackTurtle";
import MarineIguana from "../Screens/Species/MarineIguana";
import NassauGrouper from "../Screens/Species/NassauGrouper";
import SeaOtter from "../Screens/Species/SeaOtter";

const Navigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/atlanticbluefintuna" element={<AtlanticBluefinTuna />} />
        <Route path="/bluewhale" element={<BlueWhale />} />
        <Route path="/giantclam" element={<GiantClam />} />
        <Route path="/greathammerheadshark" element={<GreatHammerheadShark />} />
        <Route path="/greenseaturtle" element={<GreenSeaTurtle />} />
        <Route path="/horseshoecrab" element={<HorseshoeCarb />} />
        <Route path="/leatherbackturtle" element={<LeatherbackTurtle />} />
        <Route path="/marineiguana" element={<MarineIguana />} />
        <Route path="/nassaugrouper" element={<NassauGrouper />} />
        <Route path="/seaotter" element={<SeaOtter />} />
        <Route path="/vaquita" element={<Vaquita />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
