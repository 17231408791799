import React from "react";

interface Props {
  name: string;
  image: string;
  desc: string;
  link: string;
}

const Card = (props: Props) => {
  return (
    <a href={props.link} className="card">
      <img src={props.image} alt={props.name} />
      <div className="name">{props.name}</div>
      <div className="desc">{props.desc}</div>
    </a>
  );
};

export default Card;
