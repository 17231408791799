import React from "react";
import Image from "../../Assets/greathammerheadshark.png";
import "./Species.css";

const GreatHammerheadShark = () => {
  return (
    <div className="cont">
      <div className="card">
        <div className="top">
          <img src={Image} alt="Great Hammerhead Shark" />
          <div>
            <div className="head">Great Hammerhead Shark</div>
            <div className="desc">
              The Great Hammerhead Shark (Sphyrna mokarran) is a distinctive and powerful predator, easily recognized by its unique "hammer" shaped head. This large shark, which
              can grow up to 20 feet in length, roams tropical and warm temperate waters across the globe. The Great Hammerhead is known for its excellent hunting abilities, often
              preying on stingrays, fish, squid, and crustaceans. Unfortunately, this species is now considered endangered due to overfishing and the demand for shark fins,
              highlighting the urgent need for conservation efforts to protect this iconic marine predator.
            </div>
          </div>
        </div>
        <div className="head">Characteristics and Behavior</div>
        <div className="desc">
          Great Hammerhead Sharks are notable for their wide, flat, hammer-shaped heads, which improve their ability to detect prey through sensory organs called ampullae of
          Lorenzini. These electroreceptors help the shark locate prey hidden in the sand. The Great Hammerhead is a solitary hunter and can be found swimming close to the ocean
          floor, where it uses its head to pin down stingrays. It is known for its agility and power, allowing it to capture a variety of prey with ease.
        </div>
        <div className="head">Habitat and Range</div>
        <div className="desc">
          Great Hammerhead Sharks are widely distributed across tropical and warm temperate waters worldwide, including areas in the Atlantic, Indian, and Pacific Oceans. They
          inhabit coastal areas and continental shelves, often venturing near coral reefs and mangroves. Although they are generally found in shallow waters, they can dive to
          depths of up to 300 feet. Their wide range makes them particularly vulnerable to overfishing, as they often come into contact with commercial fisheries.
        </div>
        <div className="head">Conservation Status and Threats</div>
        <div className="desc">
          The Great Hammerhead Shark is classified as endangered due to overfishing and the shark fin trade. Their fins are highly prized in some regions, leading to a dramatic
          decline in populations. In addition, Great Hammerheads have low reproductive rates, with females giving birth to relatively few pups, making population recovery slow.
          Bycatch in commercial fisheries also poses a significant threat. Conservation efforts include protective regulations and establishing marine protected areas, but illegal
          fishing remains a critical challenge.
        </div>
        <div className="head">Why Saving the Great Hammerhead Shark Matters</div>
        <div className="desc">
          As an apex predator, the Great Hammerhead Shark plays an essential role in maintaining the balance of marine ecosystems. By controlling the populations of prey species,
          they help ensure a healthy food web, which supports biodiversity in their habitats. Protecting Great Hammerheads contributes to the resilience of coral reefs, seagrass
          beds, and other coastal ecosystems. Preserving this species also has cultural and ecological value, highlighting the importance of sharks to ocean health.
        </div>
        <div className="head">How to Help</div>
        <div className="desc">
          Supporting organizations like the Shark Trust and the World Wildlife Fund (WWF), which work to protect shark populations and combat the shark fin trade, can make a
          significant impact. Advocating for sustainable fishing practices and raising awareness about the importance of sharks in marine ecosystems helps foster protective
          policies. Choosing sustainable seafood options also reduces demand for practices that harm sharks and their habitats.
        </div>
      </div>
      <a href="/">Go Back</a>
    </div>
  );
};

export default GreatHammerheadShark;
