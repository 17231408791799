import React from "react";
import AtlanticBluefinTuna from "../../Assets/atlanticbluefintuna.png";
import BlueWhale from "../../Assets/bluewhale.png";
import GiantCalm from "../../Assets/giantclam.png";
import GreatHammerheadShark from "../../Assets/greathammerheadshark.png";
import GreenSeaTurtle from "../../Assets/greenseaturtle.png";
import HorseshoeCrab from "../../Assets/horseshoecrab.png";
import LeatherbackTurtle from "../../Assets/leatherbackturtle.png";
import MarineIguana from "../../Assets/marineiguana.png";
import NassauGrouper from "../../Assets/nassaugrouper.png";
import SeaOtter from "../../Assets/seaotter.png";
import Vaquita from "../../Assets/vaquita.png";
import Card from "./Card";
import "./Home.css";

const Home = () => {
  return (
    <div className="home">
      <div className="header">We Are 8 Billion Strong; They Are on the Brink</div>
      <div className="cards">
        <Card
          name="Atlantic Bluefin Tuna"
          image={AtlanticBluefinTuna}
          link="/atlanticbluefintuna"
          desc="Impacted by overfishing and climate change, which affects prey availability."
        />
        <Card name="Blue Whale" image={BlueWhale} link="/bluewhale" desc="The largest animal on Earth, threatened by ship strikes, noise pollution, and climate change." />
        <Card name="Giant Calm" image={GiantCalm} link="/giantclam" desc="Critical reef species, endangered by poaching for meat and shells." />
        <Card
          name="Great Hammerhead Shark"
          image={GreatHammerheadShark}
          link="/greathammerheadshark"
          desc="At risk due to overfishing and demand for fins, highly sensitive to environmental changes."
        />
        <Card name="Green Sea Turtle" image={GreenSeaTurtle} link="/greenseaturtle" desc="Endangered due to habitat loss, hunting, and climate change impacting nesting sites." />
        <Card name="Horseshoe Crab" image={HorseshoeCrab} link="/horseshoecrab" desc="Important to both marine ecosystems and medical research, endangered by overharvesting." />
        <Card
          name="Leatherback Turtle"
          image={LeatherbackTurtle}
          link="/leatherbackturtle"
          desc="The largest sea turtle, threatened by egg poaching, pollution, and coastal development."
        />
        <Card name="Marine Iguana" image={MarineIguana} link="/marineiguana" desc="Threatened by climate change affecting food sources and habitat." />
        <Card name="Nassau Grouper" image={NassauGrouper} link="/nassaugrouper" desc="Vital reef species, endangered from overfishing and loss of coral habitats." />
        <Card name="Sea Otter" image={SeaOtter} link="/seaotter" desc="Vital to kelp forest ecosystems, endangered by hunting, pollution, and habitat degradation." />
        <Card name="Vaquita" image={Vaquita} link="/vaquita" desc="Critically endangered porpoise found only in the Gulf of California, facing threats from illegal fishing." />
      </div>
    </div>
  );
};

export default Home;
